import { Avatar, Button, Group, Menu, Text } from '@mantine/core';
import { signOut, useSession } from 'next-auth/react';
import { ApiUser } from '@/types/user';
import { clearCookies } from '@/app/proxy-server-cookie';
import { canUseAdminFeatures, urlHelper } from '@/utils';
import { DropdownMenuItem } from '@/app/components/navbar/DropdownMenuItem';
import externalLinks from '@/utils/externalLinks';
import { useDisclosure } from '@mantine/hooks';
import { ImpersonateUserModal } from './ImpersonateModal';
import { useImpersonationQuery } from '@/shared/useImpersonationQuery';
import { ImpersonationMenuItem } from './ImpersonationMenuItem';
import { Crisp } from 'crisp-sdk-web';
import { CrownMenuItem } from './CrownMenuItem';
import { IconBuilding } from '@tabler/icons-react';
import { getPlansWithoutApiAccess, getPlansWithoutCliAccess } from '@/utils/plans';
import Link from 'next/link';

export function AccountPagesMenu() {
  const { data: session } = useSession();
  const [impersonationModalOpened, { open: impersonationModalOpen, close: impersonationModalClose }] =
    useDisclosure(false);
  const canUseAdmin = canUseAdminFeatures(session?.user);
  const { data: impersonation } = useImpersonationQuery();

  if (!session?.user) {
    return null;
  }

  const customSignOut = async () => {
    await signOut({ redirect: true });
    clearCookies();
    Crisp.setTokenId();
    Crisp.session.reset();
  };

  const userPlan = session?.user?.plan?.details?.id;

  const showCrownIcon = getPlansWithoutApiAccess().includes(userPlan);
  const hasCliAccess = !getPlansWithoutCliAccess().includes(userPlan);

  // Show Upgrade button if user is on trial or plan is 1 (Free/Unsubscribed)
  const onTrial = session?.user?.plan?.on_new_trial;
  const showUpgradeButton = [1].includes(userPlan) || onTrial;
  const handleUpgradeClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <>
      <Group gap='xs' wrap='nowrap'>
        <Menu shadow='md' width={200}>
          <Menu.Target>
            <Group gap='xs' style={{ cursor: 'pointer' }}>
              {showUpgradeButton && (
                <Button
                  variant='outline'
                  component={Link}
                  href={urlHelper.plan.choosePlan()}
                  onClick={handleUpgradeClick}
                >
                  Upgrade
                </Button>
              )}
              <Avatar
                component='a'
                color={impersonation?.isImpersonating ? 'red.6' : 'gray.6'}
                style={{ cursor: 'pointer' }}
                src={impersonation?.isImpersonating ? null : session.user.avatar}
              >
                {getInitials(session.user, impersonation?.email)?.toLocaleUpperCase()}
              </Avatar>
              <Text size='sm' c={impersonation?.isImpersonating ? 'red.6' : 'black'}>
                {impersonation?.email ?? session.user.email}
              </Text>
            </Group>
          </Menu.Target>
          <Menu.Dropdown>
            <DropdownMenuItem link={urlHelper.settings()}>Settings & Upgrade Plan</DropdownMenuItem>
            <DropdownMenuItem link={urlHelper.usageStatistics()}>Usage Statistics</DropdownMenuItem>
            <DropdownMenuItem link={urlHelper.cli()}>
              <Group style={{ width: '100%', justifyContent: 'space-between' }}>
                CLI
                {!hasCliAccess && <IconBuilding size={16} color='black' />}
              </Group>
            </DropdownMenuItem>{' '}
            <CrownMenuItem link={urlHelper.plugins()} showCrown={showCrownIcon}>
              Plugins
            </CrownMenuItem>{' '}
            <CrownMenuItem link={urlHelper.webhooks()} showCrown={showCrownIcon}>
              Webhooks
            </CrownMenuItem>
            <CrownMenuItem link={urlHelper.apiTokens()} showCrown={showCrownIcon}>
              API Tokens
            </CrownMenuItem>
            {canUseAdmin && <DropdownMenuItem link={urlHelper.admin()}>Admin Panel</DropdownMenuItem>}
            <ImpersonationMenuItem open={impersonationModalOpen} />
            <Menu.Item onClick={customSignOut}>Log out</Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      <ImpersonateUserModal opened={impersonationModalOpened} close={impersonationModalClose} />
    </>
  );
}

function getInitials(user: ApiUser, impersonatedEmail?: string) {
  if (impersonatedEmail) {
    return impersonatedEmail.split('@')?.[0].replace(/\./g, ' ').substring(0, 2);
  }
  const email = user.email.split('@')?.[0].replace(/\./g, ' ');
  return user.address.first_name ? `${user.address.first_name[0]}${user.address.last_name[0]}` : email?.substring(0, 2);
}
